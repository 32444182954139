import { Flex, FlexProps } from '@chakra-ui/core'
import * as React from 'react'
import { Helmet } from 'react-helmet'
// import styled from '@emotion/styled'
// import { useFetchLegalitiesQuery } from '../generated/graphql'
// import { ApolloError } from 'apollo-boost'
// import { ERROR_TOAST } from '../constants'
// import { useMediaQuery } from 'react-responsive'

type PageWrapProps = FlexProps & {
  title: string
}

// type LinkProps = {
//   ml?: string
// }

// const Link = styled.a<LinkProps>`
//   margin-left: ${(props) => props.ml};
//   color: red;
// `

// type CookiesBannerProps = {
//   setCookiesAccepted: (input: string) => void
// }

// const CookiesBanner: React.FC<CookiesBannerProps> = ({ setCookiesAccepted }) => {
//   const toast = useToast()
//   const isMobile = useMediaQuery({ query: '(max-width: 55em)' })
//   const { data: legalities } = useFetchLegalitiesQuery({
//     onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST })
//   })

//   const privacyPolicyFile = legalities?.legality?.privacyPolicyFile?.url

//   const acceptCookiesFn = () => {
//     localStorage.setItem('accept_cokies', 'true')
//     setCookiesAccepted('true')
//   }

//   return (
//     <Flex
//       width="100%"
//       height="auto"
//       backgroundColor="white"
//       p={5}
//       borderTop="1px solid #E2E8F0"
//       position="fixed"
//       bottom="0"
//       className="banner-wrapper"
//       alignItems="center"
//       flexDirection={isMobile ? 'column' : 'row'}
//     >
//       <Flex textAlign="center" pl={2} mb={2} fontSize={14}>
//         By clicking “Accept all cookies”, you agree to the storing of cookies on your device to
//         enhance site navigation, analyze site usage, and assist in our marketing efforts
//       </Flex>
//       <Flex m={2} pb={1} justifyContent="center" alignItems="center">
//         {' '}
//         <Link href={privacyPolicyFile || ''} target="_blank" rel="noopener noreferrer">
//           Privacy policy
//         </Link>
//       </Flex>
//       <Flex ml={4}>
//         <Button width="100%" onClick={() => acceptCookiesFn()} variantColor="green">
//           Accept all cookies
//         </Button>
//       </Flex>
//     </Flex>
//   )
// }

const PageWrap: React.FC<PageWrapProps> = ({ children, title, ...rest }) => {
  // const [cookiesAccepted, setCookiesAccepted] = React.useState(
  //   localStorage.getItem('accept_cokies')
  // )

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex {...rest}>{children}</Flex>
      {/* {cookiesAccepted !== 'true' && <CookiesBanner setCookiesAccepted={setCookiesAccepted} />} */}
    </>
  )
}

PageWrap.defaultProps = {
  flex: 1,
  pt: 'calc(64px + 1rem)',
  height: '100%',
  flexDir: 'column',
  minHeight: '100vh',
  align: 'flex-start',
  justify: 'flex-start'
}

export default PageWrap
